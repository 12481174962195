import styled from 'styled-components';

export const PageLoadingStyles = styled.div`
    .content-loading {
        align-items: center;
        justify-content: center;
        min-height: 600px;
        z-index: 9000;
    }
`;
