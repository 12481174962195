import LoadingGif from '../../../assets/images/loading.gif';
import { PageLoadingStyles } from './PageLoading.styles';

export default function PageLoading() {
  return (
    <PageLoadingStyles>
      <div className="d-flex content-loading">
        <div className="text-center">
          <img className="loading-gif" src={LoadingGif} />
          <br />
          Attendere prego
        </div>
      </div>
    </PageLoadingStyles>
  );
}
