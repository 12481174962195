import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  auto: {
    loading: true,
    loadingQuotes: false,
    isLoadingVehicleModels: false,
    isLoadingVehicleVersions: false,
    isLoadingFastQuote: false,
    isSubmitting: false,
    index: 1,
    states: [],
    municipalities: [],
    vehicleModels: [],
    dataPrivacy: [],
    brands: [],
    formData: {
      qualifications: [],
      professions: [],
      parking_types: [],
      theft_protections: [],
      marital_statuses: [],
      insurance_companies: [],
      company_types: [],
    },
    vehicleVersions: [],

    driverForm: false,
    ownerForm: false,

    searchResults: [],
    selectedClientOldData: null,
    selectedClient: null,
    selectedClientId: null,

    fastQuoteVehicleInfo: null,
  },
};

export const formsSlice = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    autoFormDataUpdated: (state, { payload }) => {
      state.auto = { ...state.auto, ...payload };
    },
    resetAutoForm: (state) => {
      state.auto = { ...initialState.auto };
    },
  },
});
