import axe from '../../services/axios-client/axiosClient';
import caller from './teamCaller';

export const getTeam = async (page = null) => {
  caller.teamsPending();
  try {
    let response = null;

    if (page === null) {
      response = await axe.get('/team');
    }

    if (page !== null) {
      response = await axe.get('/team', {
        params: {
          page,
        },
      });
    }

    if (response.status !== 200) {
      caller.teamsRejected('Error');
    }

    if (response.status === 200) {
      caller.teamsSuccess(response.data);
    }
  } catch (error) {
    caller.teamsRejected('Error');
  }
};

export const getSingleTeam = async (teamSlug) => {
  caller.teamPending();
  try {
    const response = await axe.get(`/team/${teamSlug}`);

    if (response.status !== 200) {
      caller.teamRejected('Error');
    }

    if (response.status === 200) {
      caller.teamSuccess(response.data);
    }
  } catch (error) {
    caller.teamRejected('Error');
  }
};
