import styled from 'styled-components';
import { LightColor, PrimaryColor } from '../utils/StyleVariables';

export const NavbarStyles = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0px;
  position: relative;
  width: 90%;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 550px) {
    padding: 0px;
  }
`;

export const Logo = styled.a`
  color: ${LightColor};
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    transition: all 200ms ease-in-out;
  }

  img:hover {
    transform: scale(1.05);
  }

  @media (max-width: 550px) {
    img {
      width: 150px;
    }
  }
`;

export const LoginButton = styled.a`
  text-decoration: none;
  width: 160px;
  height: 56px;
  border-radius: 40px;
  border: 1px solid rgba(255, 255, 255, 0.349);
  background-color: rgb(12, 12, 12);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition-duration: 0.3s;
  overflow: hidden;

  .IconContainer {
    width: 52px;
    height: 50px;
    background: ${PrimaryColor};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 2;
    transition-duration: 0.3s;
  }

  .icon {
    border-radius: 1px;
  }

  .text {
    height: 100%;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    z-index: 1;
    transition-duration: 0.3s;
    font-size: 1.04em;
  }

  &:hover .IconContainer {
    width: 156px;
    border-radius: 40px;
    transition-duration: 0.3s;
  }

  &:hover .text {
    transform: translate(10px);
    width: 0;
    font-size: 0;
    transition-duration: 0.3s;
  }

  &:active {
    transform: scale(0.95);
    transition-duration: 0.3s;
  }

  @media (max-width: 999px) {
    width: 100px;
    height: 40px;

    .IconContainer {
      width: 30px;
      height: 30px;
    }

    .text {
      width: 60px;
      font-size: 0.95em;
      margin: 0;
    }

    &:hover .IconContainer {
      width: 90px;
    }
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    gap: 30px;

    li {
      position: relative;
      transition: all ease-in-out 200ms;
      padding-bottom: 5px;
      text-shadow: 0.2px 0.2px ${PrimaryColor};
      color: ${PrimaryColor};

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: ${PrimaryColor};
        transition: all ease-in-out 200ms;
        transform-origin: center;
        transform: scaleX(0);
      }

      &:hover::before {
        transform: scaleX(1);
      }

      a {
        color: ${PrimaryColor};
        font-family: "Tobi Pro", sans-serif;
        font-size: 20px;
        text-transform: uppercase;
        text-decoration: none;
      }
    }
  }

  @media (max-width: 999px) {
    display: none;
  }
`;

export const NavMenuMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  position: absolute;
  top: 70px;
  right: 0;
  z-index: 999;
  flex-direction: column;
  background-color: ${LightColor};
  width: 100%;
  max-width: 300px;
  padding: 20px 0px;
  border-radius: 10px;

  box-shadow: 0px 5px 5px 3px rgba(0, 137, 68, 0.3);

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    gap: 30px;
    flex-direction: column;
    padding-left: 0;

    li {
      position: relative;
      transition: all ease-in-out 200ms;
      padding-bottom: 5px;

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: ${PrimaryColor};
        transition: all ease-in-out 200ms;
        transform-origin: center;
        transform: scaleX(0);
      }

      &:hover::before {
        transform: scaleX(1);
      }

      a {
        color: ${PrimaryColor};
        font-family: "Tobi Pro", sans-serif;
        font-size: 20px;
        text-transform: uppercase;
        text-decoration: none;
      }
    }
  }

  @media (min-width: 999px) {
    display: none;
  }
`;

export const ToggleButton = styled.button`
  display: none;

  @media (max-width: 999px) {
    display: block;
    background-color: transparent;
    box-shadow: none;
    border: none;
    font-size: 30px;
    color: ${PrimaryColor};
  }
`;
