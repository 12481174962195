import styled from 'styled-components';
import { SecondaryColor } from '../utils/StyleVariables';

export const PartnersWrapper = styled.section`
  background-color: #eee;
  padding: 20px 0;

  h2 {
    font-family: "Tobi Pro", sans-serif;
    font-size: 30px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 0;
    color: ${SecondaryColor};
  }

  @media (max-width: 999px) {
    h2 {
      font-size: 24px;
    }
  }
`;

export const PartnersContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 250px);
  grid-template-rows: repeat(2, auto);
  align-items: center;
  justify-content: center;
  gap: 5px;
  place-items: center;
  margin-bottom: 30px;
  
  @media (max-width: 999px) {
    grid-template-columns: repeat(2, 180px);
    grid-template-rows: auto;
    gap: 5px;
  }

  @media (max-width: 1440px) {
    flex-wrap: wrap;
    gap: 5px;
  }
`;

export const Partner = styled.div`
  padding: 0px;
  img {
    width: 140px;
    transition: all ease-in-out 200ms;
  }

  img:hover {
    transform: scale(1.03);
  }

  .linear-logo{
    width: 110px;
    transition: all ease-in-out 200ms;
  }
  .europ-assis{
    width:105px;
    height: 75px;
    transition: all ease-in-out 200ms;
  }

  .dallbog-size {
    width: 100px;
  }

  .global-assistance {
    width: 200px;
  }

  @media (max-width: 999px) {
    img {
      width: 100px;
    }
    padding: 10px;


  .linear-logo{
    width: 90px;
    transition: all ease-in-out 200ms;
  }

  .europ-assis{
    width:80px;
    height: 60px;
    transition: all ease-in-out 200ms;
  }
  }

`;
