import styled from 'styled-components';
import { PrimaryColor } from '../utils/StyleVariables';

export const TeamStyles = styled.div``;

export const TeamTitle = styled.div`
 h1{
        text-align: center;
        font-family: "Merriweather", sans-serif;
        font-weight: 700;
        color: ${PrimaryColor};
        font-size: 40px;
        margin-bottom: 0;
        font-size: 30px;
        margin-top: 50px;
    }
`;

export const CardTeam = styled.div`
    .card-img-top{
        height: 350px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        object-fit: cover;
        object-position: top;
    
    }

    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(198 , 198 , 198, 0.2), 0 6px 5px 0 rgba(198 , 198 , 198, 0.19);
   .card-body h2{
    font-family: "Merriweather", sans-serif;
    font-size: 19px;
    color: ${PrimaryColor};

   }

   .card-body p{
    font-size: 15px;
   }
`;

export const MoreInfoTeam = styled.div`
    h6{
        font-size: 14px;
        color: #636363;
        font-style: italic;
        font-weight: 300;
    }

    a{
        font-size: 15px;
    }

`;

export const SingleTeamStyles = styled.div`
    h2{
        color: ${PrimaryColor};
        font-family: "Merriweather", sans-serif;
    }

    h3{
        font-size: 17px;
        font-weight: 400;
        font-style: italic;
    }
    p{
        margin-top: 30px;
        font-size: 16px;
        font-weight: 400;
    }
   
   
`;

export const NoTeamFound = styled.div`
    border: 1px solid ${PrimaryColor};
    /* box-shadow: 0 0 0 .25rem rgba(0 , 137 , 68, .25); */
    background-color:rgba(0 , 137 , 68, .25);
`;

export const SingleTeamImg = styled.div`
  
    display: flex;
    justify-content: end;
    align-items: center;

    img{
        height: 600px;
        max-width: 100%;
        border-radius: 10px;
        box-shadow: 0 4px 8px 0 rgba(198 , 198 , 198, 0.2), 0 6px 5px 0 rgba(198 , 198 , 198, 0.19);

    };
  
    @media screen and (max-width: 767px) {
        max-width: 100%;
        img{
        height: 100%;
    }
    }

    /* @media screen and (max-width: 768px) {
        max-width: 100%;

        img{
        width: 100%;
        object-fit: cover;
        height: 720px;
        object-position: top;
    }
    } */

    @media (min-width: 768px) and (max-width: 991px) {
        max-width: 100%;
        justify-content: center;
    }

    @media (min-width: 992px) and (max-width: 1200px) {
        img{
        object-fit: cover;
    }
    }

   
`;
