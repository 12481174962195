import { generalAlertError } from '../../common/utils/alerts';
import axe from '../../services/axios-client/axiosClient';
import caller from './chatFormCaller';

export const chatFormToken = async (
  data,
  recaptchaReset,
  headers,
  navigate,
) => {
  caller.chatFormPending();

  try {
    const response = await axe.post('/store-chat-information', data, {
      headers,
    });
    if (response.status !== 200) {
      generalAlertError('Errore');
      caller.chatFormRejected();
      recaptchaReset();
      return;
    }
    if (response.status === 200) {
      localStorage.setItem('token', response.data.data.access_token);
      localStorage.setItem('formAuth', JSON.stringify(response.data.data.data));
      caller.chatFormSuccess();
      navigate('/chat', { replace: true });
      recaptchaReset();
    }
  } catch (error) {
    generalAlertError('Errore');
    recaptchaReset();
    caller.chatFormRejected();
  }
};
