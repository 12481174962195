import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { HomeWrapper } from './Home.styles';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { ThankYouContainer } from './ThankYouPage.styles';

function ThankYouPage() {
  const [show, setShow] = useState(false);

  const closeMenu = () => {
    if (show === true) {
      setShow(false);
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Greenia SRL | Collabora Con Noi</title>
        <meta name="description" content="Scopri tutto quello che devi sapere sulle assicurazioni nel nostro blog!" />
      </Helmet>
      <HomeWrapper onClick={() => closeMenu()}>
        <Navbar show={show} setShow={setShow} />
        <ThankYouContainer className="d-flex justify-content-center flex-column align-items-center">
          <h1 className="text-center">Grazie</h1>
          <p className="mb-5 h6 text-center">Abbiamo ricevuto le tue informazioni. Sarai contattato a breve dal nostro team.</p>
          <Link to="/" className="btn btn-success py-2 px-5">Torna Al Home</Link>
        </ThankYouContainer>
        <Footer />
      </HomeWrapper>
    </>
  );
}

export default ThankYouPage;
