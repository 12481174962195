import React from 'react';
import { motion } from 'framer-motion';
import { Partner, PartnersContainer, PartnersWrapper } from './Partners.styles';
import adriaticLogo from '../assets/images/adriatic-logo.svg';
import primaLogo from '../assets/images/prima-logo.svg';
import tokiomarineLogo from '../assets/images/tokio-marine-logo.svg';
import europ from '../assets/images/europeassistance-logo.webp';
import linear from '../assets/images/linear-logo.svg';
import dallbogg from '../assets/images/dallbog.png';
import globalAssistance from '../assets/images/global-assistance.png';
import euroherc from '../assets/images/euroherc-logo.png';

function Partners() {
  const cardVariants = {
    offscreen: { opacity: 0, y: -20 },
    onscreen: {
      opacity: 1,
      y: 0,

      transition: {
        duration: 0.7,
      },
    },
  };
  return (
    <PartnersWrapper>
      <h2>I Nostri Partner</h2>
      <PartnersContainer>
        <Partner>
          <motion.img
            src={adriaticLogo}
            alt="Adriatic Assicurazioni Logo"
            variants={cardVariants}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}
          />
        </Partner>
        <Partner>
          <motion.img
            src={primaLogo}
            alt="Prima Assicurazioni Logo"
            variants={cardVariants}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}
          />
        </Partner>
        <Partner>
          <motion.img
            className="linear-logo"
            src={linear}
            alt="Linear Group"
            variants={cardVariants}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}
          />
        </Partner>
        <Partner>
          <motion.img
            src={tokiomarineLogo}
            alt="Tokio Marine Logo"
            variants={cardVariants}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}
          />
        </Partner>
        <Partner>
          <motion.img
            src={europ}
            className="europ-assis"
            alt="Europ Assistance"
            variants={cardVariants}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}
          />
        </Partner>
        <Partner>
          <motion.img
            src={euroherc}
            alt="Euroherc"
            variants={cardVariants}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}
          />
        </Partner>
        <Partner>
          <motion.img
            src={dallbogg}
            alt="Dallbog"
            variants={cardVariants}
            initial="offscreen"
            whileInView="onscreen"
            className="dallbog-size"
            viewport={{ once: true, amount: 0.8 }}
          />
        </Partner>
        <Partner>
          <motion.img
            src={globalAssistance}
            alt="Global Assistance"
            variants={cardVariants}
            initial="offscreen"
            whileInView="onscreen"
            className="global-assistance"
            viewport={{ once: true, amount: 0.8 }}
          />
        </Partner>
      </PartnersContainer>
    </PartnersWrapper>
  );
}

export default Partners;
