import styled from 'styled-components';
import { motion } from 'framer-motion';
import {
  LightColor,
  PrimaryColor,
  SecondaryColor,
} from '../utils/StyleVariables';

export const FAQSectionWrapper = styled.section`
  padding: 50px 0;
  max-width: 1440px;
  margin: 0 auto;
`;

export const Container = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 999px) {
    flex-direction: column;
  }
`;

export const ColOne = styled(motion.div)`
  width: 50%;
  padding: 30px;

  h3 {
    font-family: "Tobi Pro", sans-serif;
    font-size: 36px;
    color: ${PrimaryColor};
  }

  p {
    font-size: 16px;
    margin-bottom: 50px;

    span {
      display: block;
      font-family: "Tobi Pro", sans-serif;
      font-size: 18px;
      font-weight: 500;
      color: ${SecondaryColor};
      position: relative;
      margin-bottom: 15px;

      &::before {
        content: "";
        position: absolute;
        bottom: -5px;
        left: 0;
        height: 4px;
        width: 30px;
        background-color: ${PrimaryColor};
      }
    }
  }

  img {
    width: 100%;
    border-radius: 30px;
  }

  @media (max-width: 999px) {
    width: 100%;
    padding: 0;
  }
`;

export const ColTwo = styled.div`
  width: 50%;
  padding: 30px;

  h3 {
    font-family: "Tobi Pro", sans-serif;
    font-size: 36px;
    color: ${PrimaryColor};
  }

  img {
    width: 100%;
    border-radius: 30px;

    &.mobile {
      display: none;
    }
  }

  @media (max-width: 999px) {
    width: 100%;
    padding: 0;

    img.desktop {
      display: none;
    }
    img.mobile {
      display: block;
      margin-top: 20px;
      display: none;
    }
  }
`;

export const Accordion = styled.div`
  div.h5-accordion {
    font-family: "Tobi Pro", sans-serif;
    font-size: 18px;
    padding: 15px;
    background-color: #008e47;
    border-radius: 15px;
    color: ${LightColor};
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    margin-bottom: 0px;
    margin-top: 5px;
    text-shadow: 1px 1px black;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    padding: 15px;
    background-color: #008e47;
    border-radius: 15px;
    color: ${LightColor};
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    transition: all 200ms ease-in-out;
    transform: scaleY(0);
    transform-origin: top;
    text-shadow: 1px 1px black;

    &.open {
      transform: scaleY(1) !important;
    }
  }

  @media (max-width: 999px) {
    div.h5-accordion {
      font-size: 16px;
    }
  }
`;
