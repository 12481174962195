import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  teams: {
    loading: true,
    error: null,
    teams: [],
    page: 1,
    totalPages: 0,
  },
  team: {
    loading: true,
    error: null,
    team: null,
  },
};

export const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    teamsPending: (state) => {
      state.teams.loading = true;
      state.teams.error = null;
    },
    teamsRejected: (state, { payload }) => {
      state.teams.loading = false;
      state.teams.error = payload;
    },
    teamsSuccess: (state, { payload }) => {
      state.teams.loading = false;
      state.teams.teams = payload.data;
      state.teams.page = payload.data.current_page;
      state.teams.totalPages = payload.data.last_page;
    },
    teamPending: (state) => {
      state.team.loading = true;
      state.team.error = null;
    },
    teamRejected: (state, { payload }) => {
      state.team.loading = false;
      state.team.error = payload;
    },
    teamSuccess: (state, { payload }) => {
      state.team.loading = false;
      state.team.team = payload.data;
    },

  },
});
