import React from 'react';
import {
  Card,
  CardContainer,
  ShortInfoSectionStyles,
} from './ShortInfoSection.styles';
import icon1 from '../assets/images/icon-1.svg';
import icon2 from '../assets/images/icon-2.svg';
import icon3 from '../assets/images/icon-3.svg';

function ShortInfoSection() {
  const cardVariants = {
    offscreen: { opacity: 0, y: -20 },
    onscreen: {
      opacity: 1,
      y: 0,

      transition: {
        duration: 0.8,
      },
    },
  };

  return (
    <ShortInfoSectionStyles>
      <CardContainer>
        <Card
          variants={cardVariants}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }}
        >
          <img src={icon1} alt="" width={80} />
          <h3 className="mt-2">Facile</h3>
          <p>
            Una digital suite, studiata per semplificare il lavoro ai nostri
            collaboratori.
          </p>
        </Card>
        <Card
          variants={cardVariants}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }}
        >
          <img src={icon2} alt="" width={80} />
          <h3 className="mt-2">Tecnologia</h3>
          <p>Le più moderne tecnologie e la migliore esperienza utente.</p>
        </Card>
        <Card
          variants={cardVariants}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }}
        >
          <img src={icon3} alt="" width={80} />
          <h3 className="mt-2">Guadagni</h3>
          <p>
            La metà delle provvigioni maturate da Greenia, nel tuo portafogli!
          </p>
        </Card>
      </CardContainer>
    </ShortInfoSectionStyles>
  );
}

export default ShortInfoSection;
