import styled from 'styled-components';
import { PrimaryColor } from '../utils/StyleVariables';

export const OurProductsWrapper = styled.section`
  border-top: 2px solid #dddddd;
  border-bottom: 2px solid #dddddd;
  margin: 50px 0;
  width: 100%;
  max-width: 100%;

  @media (max-width: 999px) {
    display: none;
  }
`;

export const ProductsContainer = styled.div`
  gap: 30px;
  max-width: 1440px;
  margin: 0 auto;

  & .container {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ProductCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 10px;
  width: 100%;
  position: relative;

  &.border-custom::after {
    content: "";
    position: absolute;
    right: 0;
    width: 2px;
    height: 80%;
    background-color: #dddddd;
  }

  p {
    font-family: "Tobi Pro", sans-serif;
    font-size: 20px;
    color: ${PrimaryColor};
  }

  img {
    width: 50px;
  }
`;
