import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  BrowsersList,
  PrivacyBody, PrivacyPolicyStyles, PrivacyTitle, Table,
  TableWrapper,
} from './PrivacyPolicy.styles';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

function PrivacyPolicy() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Greenia SRL | Privacy Policy</title>
      </Helmet>

      <Navbar show={show} setShow={setShow} />

      <PrivacyPolicyStyles>
        <PrivacyTitle>
          <h2>Cookie Policy</h2>
        </PrivacyTitle>
        <PrivacyBody>
          <p>Con il presente documento, ai sensi dell’art. 13 del Reg. UE 679/2016 (c.d. Regolamento Privacy) e dell’art. 122 del D.Lgs 196/2003 (c.d. Codice Privacy), nonché in base a quanto previsto dal Provvedimento Generale del Garante Privacy dell’8 maggio 2014 e dalle Linee guida cookie e altri strumenti di tracciamento emesse dal Garante Privacy in data 10 giugno 2021 nonché dal Regolamento Privacy (art. 95, in particolare), il Titolare Le fornisce Le seguenti informazioni relative ai cookie utilizzati sul Sito.</p>
        </PrivacyBody>
        <PrivacyBody>
          <h5>Cosa sono i cookie</h5>
          <p>Con il presente documento, ai sensi dell’art. 13 del Reg. UE 679/2016 (c.d. Regolamento Privacy) e dell’art. 122 del D.Lgs 196/2003 (c.d. Codice Privacy), nonché in base a quanto previsto dal Provvedimento Generale del Garante Privacy dell’8 maggio 2014 e dalle Linee guida cookie e altri strumenti di tracciamento emesse dal Garante Privacy in data 10 giugno 2021 nonché dal Regolamento Privacy (art. 95, in particolare), il Titolare Le fornisce Le seguenti informazioni relative ai cookie utilizzati sul Sito.</p>
          <p className="parag-semibold">Cookie tecnici o analitici di prima parte assimilati ai cookie tecnici</p>
          <p>Sono utilizzati al solo fine di “effettuare la trasmissione di una comunicazione su una rete di comunicazione elettronica, o nella misura strettamente necessaria al fornitore di un servizio della società dell’informazione esplicitamente richiesto dall’abbonato o dall’utente a erogare tale servizio” ovvero vengono utilizzati dal gestore del sito per raccogliere informazioni sul numero degli utenti e su come questi visitano il sito. Senza il ricorso a tali cookie, alcune operazioni non potrebbero essere compiute o sarebbero più complesse e/o meno sicure. Per l’installazione di tali cookie non è necessario il consenso degli utenti. Possono essere suddivisi in: (i) cookie di navigazione o di sessione, che garantiscono la normale navigazione e fruizione del sito web (permettendo, ad esempio, di realizzare un acquisto o autenticarsi per accedere ad aree riservate); essi sono di fatto necessari per il corretto funzionamento del sito; (ii) cookie di funzionalità, che permettono all’utente una navigazione più fluida in funzione di una serie di criteri selezionati (ad esempio, la lingua, i prodotti selezionati per l’acquisto) al fine di migliorare il servizio reso allo stesso; (iii) cookie analitici, assimilati ai cookie tecnici, laddove utilizzati direttamente dal gestore del sito per produrre statistiche aggregate in relazione ad un singolo sito. Nel rispetto del vincolo di finalità il titolare più anche utilizzare i dati in chiaro.</p>
        </PrivacyBody>

        <TableWrapper>
          <Table>
            <table>
              <thead>
                <tr>
                  <th><h1>Cookie</h1></th>
                  <th><h1>Descrizione</h1></th>
                  <th><h1>Durata</h1></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>CookieConsent</td>
                  <td>Memorizza lo stato del consenso ai cookie dell'utente per il dominio corrente</td>
                  <td>1 anno</td>
                </tr>
                <tr>
                  <td>coverzen_session</td>
                  <td>Memorizza il login dell’utente all’interno della sua area personale</td>
                  <td>2 ore</td>
                </tr>
                <tr>
                  <td>XSRF-TOKEN</td>
                  <td>Cookie tecnico di sicurezza che protegge l’utente del sito web da attacchi CSRF</td>
                  <td>Durata della sessione</td>
                </tr>
              </tbody>
            </table>
          </Table>
        </TableWrapper>
        <PrivacyBody>
          <p className="parag-semibold">Cookie analitico di terze parti opportunamente minimizzato</p>
          <p>Sono equiparati ai cookie tecnici, pertanto non richiedono la prestazione di consenso i cookie analitici di terze parti per i quali (i) viene mascherata almeno la quarta componente dell’indirizzo IP; (ii) per i quali le terze parti si astengono dal combinarli – anche minimizzati – con altre elaborazioni ovvero (iii) trasmetterli ad ulteriori terzi. Alle terze parti è comunque consentita la produzione di statistiche con dati relativi a più domini riconducibili al medesimo Titolare.</p>
        </PrivacyBody>
        <TableWrapper>
          <Table>
            <table>
              <thead>
                <tr>
                  <th><h1>Cookie</h1></th>
                  <th><h1>Descrizione</h1></th>
                  <th><h1>Durata</h1></th>

                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Google Analytics</td>
                  <td>Google Analytics è un servizio fornito da Google LLC oppure da Google Ireland Limited, a seconda della posizione in cui il sito viene utilizzato. Google genera dati statistici su come i visitatori utilizzano il sito internet.</td>
                  <td>
                    <div>AMP_TOKEN: 1 ora</div>
                    <div>__utma: 2 anni</div>
                    <div>__utmb: 30 minuti</div>
                    <div>__utmc: durata della sessione</div>
                    <div>__utmt: 10 minuti</div>
                    <div> __utmv: 2 anni</div>
                    <div>__utmz: 7 mesi</div>
                    <div>_ga: 2 anni</div>
                    <div>_gac*: 3 mesi</div>
                    <div>_gat: 1 minuto</div>
                    <div>_gid: 1 giorno</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </Table>
        </TableWrapper>
        <PrivacyBody>
          <p className="parag-semibold">Cookie e altri identificatori analitici e di tracciamento con funzione non tecnica</p>
          <p>I cookie e altri identificatori analitici e di tracciamento sono file di testo depositati sul computer per consentire al sito web di raccogliere informazioni sull’utilizzo del sito da parte degli utenti. Sono utilizzati per ricondurre a soggetti determinati, identificati o identificabili specifiche azioni o schemi comportamentali ricorrenti in modo da modulare a fornitura del servizio in modo sempre più personalizzato, nonché inviare messaggi pubblicitari mirati ovvero in linea con le manifestazioni di interesse dell’utente espresse nell’ambito della navigazione in rete. L’installazione di questi cookie è vincolata alla prestazione del consenso.</p>
        </PrivacyBody>

        <TableWrapper>
          <Table
            className="table-responsive"
          >
            <table>
              <thead>
                <tr>
                  <th><h1>Cookie</h1></th>
                  <th><h1>Descrizione</h1></th>
                  <th><h1>Durata</h1></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Google Ads</td>
                  <td>Servizio fornito da Google LLC oppure da Google Ireland Limited, a seconda della posizione in cui il sito viene utilizzato. Collega i dati provenienti dal network di annunci Google Ads con le azioni compiute all'interno di questo sito web.</td>
                  <td>
                    <div>IDE: 2 anni</div>
                    <div>test_cookie: 15 minuti</div>
                  </td>
                </tr>
                <tr>
                  <td>LinkedIn</td>
                  <td>Servizio di statistiche e di targeting comportamentale fornito da LinkedIn Corporation che collega i dati provenienti dal network di annunci LinkedIn con le azioni compiute all'interno di questo sito web.</td>
                  <td>
                    <div>AnalyticsSyncHistory: 1 mese</div>
                    <div>JSESSIONID: sessione</div>
                    <div>UserMatchHistory: 1 mese</div>
                    <div>bcookie: 2 anni</div>
                    <div>bscookie: 2 anni</div>
                    <div>lang: sessione</div>
                    <div>lidc: 1 giorno</div>
                    <div>lms_ads: 1 mese</div>
                    <div>lms_analytics: 1 mese</div>
                  </td>
                </tr>
                <tr>
                  <td>Remarketing Google Ads</td>
                  <td>Collega l'attività di questo sito web con il network di advertising Google Ads ed il cookie DoubleClick.</td>
                  <td>fino a 2 anni</td>
                </tr>
                <tr>
                  <td>Remarketing Google Analytics</td>
                  <td>Collega l'attività di tracciamento effettuata da Google Analytics e dai suoi strumenti di tracciamento con il network di advertising Google Ads ed il cookie Doubleclick.</td>
                  <td>
                    <div>_gac*: 3 mesi</div>
                    <div>_gcl_: 3 mesi</div>
                  </td>
                </tr>
                <tr>
                  <td>Hotjar</td>
                  <td>Hotjar è un servizio di heat mapping e di registrazione delle sessioni fornito da Hotjar Ltd che permette di identificare il comportamento dell’utente all’interno del sito web. Hotjar rispetta gli header generici “Do Not Track”.</td>
                  <td>
                    <div> _hjAbsoluteSessionInProgress: 1 giorno</div>
                    <div>_hjFirstSeen: 1 giorno</div>
                    <div>_hjSession_*: 1 giorno</div>
                    <div>_hjSessionUser_*: 1 anno</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </Table>
        </TableWrapper>
        <PrivacyBody>
          <h5>Tipologie di cookie utilizzate dal Sito</h5>
          <p>Come da elenco fornito nelle tabelle che precedono, il Sito utilizza (i) cookie tecnici di prima parte (c.d. cookies di “terze parti”), non eliminabili in quanto essenziali per il corretto funzionamento del sito; (ii) analitici minimizzati; e – dietro prestazione del consenso – (iii) analitici di terze parti non minimizzati e di tracciamento.</p>
        </PrivacyBody>
        <PrivacyBody>
          <h5>Modalità del trattamento</h5>
          <p>Il trattamento viene effettuato con strumenti automatizzati idonei a garantirne, in relazione alle finalità per le quali essi sono stati raccolti, la sicurezza, nonché ad evitare accessi non autorizzati da parte di terzi e, comunque, nel rispetto di quanto indicato dalla normativa nazionale ed europea. Il trattamento è svolto dal Titolare, dai Responsabili e dagli Incaricati del trattamento all’uopo delegati. Non viene effettuata alcuna diffusione.</p>
        </PrivacyBody>
        <PrivacyBody>
          <h5>Conferimento dei dati</h5>
          <p>Fatta eccezione per i cookie tecnici strettamente necessari alla normale navigazione all’interno del sito, il conferimento dei dati è rimesso alla Sua volontà, che potrà esprimere, dopo aver preso visione dell’informativa breve o di quella estesa, tramite l’apposito banner. Può quindi evitare l’installazione dei cookie non tecnici cliccando il tasto “rifiuta” contenuto nel banner, nonché attraverso le apposite funzioni disponibili sul Suo browser.</p>
        </PrivacyBody>
        <PrivacyBody>
          <h5>Disabilitazione dei cookie</h5>
          <p>In aggiunta a quanto indicato in questo documento, Lei potrà gestire le preferenze relative ai cookie direttamente all’interno del Suo browser. Tramite le preferenze del browser è inoltre possibile eliminare i cookie installati in passato, incluso il cookie in cui venga eventualmente salvato il consenso all’installazione di cookie da parte del Sito.</p>
        </PrivacyBody>
        <PrivacyBody>
          <h5>Gestione delle impostazioni</h5>
          <p>Ciascun browser presenta procedure diverse per la gestione delle impostazioni. Può ottenere istruzioni specifiche attraverso i link sottostanti.</p>
          <BrowsersList>
            <ul>
              <li><a href="https://support.microsoft.com/en-us/windows" target="_blank" className="text-decoration-none text-black" rel="noreferrer">Internet Explorer</a></li>
              <li><a href="https://support.google.com/chrome/answer/95647?hl=it-IT&p=cpn_cookies" target="_blank" className="text-decoration-none text-black" rel="noreferrer">Google Chrome</a></li>
              <li><a href="https://support.mozilla.org/it/kb/protezione-antitracciamento-avanzata-firefox-desktop?redirectslug=Attivare+e+disattivare+i+cookie&redirectlocale=it" className="text-decoration-none text-black" target="_blank" rel="noreferrer">Mozilla Firefox</a></li>
              <li><a href="https://www.apple.com/legal/privacy/it/cookies/" target="_blank" className="text-decoration-none text-black" rel="noreferrer">Apple Safari</a></li>
            </ul>
          </BrowsersList>
          <p className="mb-5">
            Per avere informazioni sui cookie archiviati sul proprio terminale e disattivarli singolarmente si rinvia alla piattaforma
            <a href="https://www.youronlinechoices.com/it/le-tue-scelte" className="text-decoration-none"> Your Online Choices.</a>
          </p>
        </PrivacyBody>
      </PrivacyPolicyStyles>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
