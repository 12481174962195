import React from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import {
  LoginButton,
  Logo,
  NavMenu,
  NavMenuMobile,
  NavbarStyles,
  ToggleButton,
} from './Navbar.styles';
import { HeaderWrapper } from '../pages/Home.styles';
import logoGreen from '../assets/images/logo-green.svg';

function Navbar({ show, setShow }) {
  const toggleMenu = () => {
    setShow(!show);
  };

  return (
    <HeaderWrapper>
      <NavbarStyles>
        <Logo href="/">
          <img src={logoGreen} alt="Greenia SLR" width={200} />
        </Logo>
        <ToggleButton onClick={() => toggleMenu()}>
          {show ? <FaTimes /> : <FaBars />}
        </ToggleButton>
        <NavMenu>
          <ul className="mb-0">
            <li className="pb-0">
              <Link to="/#our-products">Prodotti</Link>
            </li>
            <li className="pb-0">
              <a href="/#chi-siamo">Chi Siamo</a>
            </li>
            <li className="pb-0">
              <Link to="/collabora-con-noi">Collabora Con Noi</Link>
            </li>
            <li className="pb-0">
              <a href="/#faq">FAQ</a>
            </li>
            <li className="pb-0">
              <a href="/blog">Blog</a>
            </li>
            <li className="pb-0">
              <a href="/team">Team</a>
            </li>
          </ul>
          <LoginButton href="https://intermediari.greenia.it" target="_blank">
            <span className="IconContainer">
              <svg
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                clipRule="evenodd"
              >
                <path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
              </svg>
            </span>
            <p className="text mb-0">Accedi</p>
          </LoginButton>
        </NavMenu>
        {show && (
          <NavMenuMobile>
            <ul>
              <li>
                <a href="#product-info">Prodotti</a>
              </li>
              <li>
                <a href="#chi-siamo">Chi Siamo</a>
              </li>
              <li>
                <Link to="/collabora-con-noi">Collabora Con Noi</Link>
              </li>
              <li>
                <a href="#faq">FAQ</a>
              </li>
              <li>
                <a href="/blog">Blog</a>
              </li>
              <li className="pb-0">
                <a href="/team">Team</a>
              </li>
            </ul>
            <LoginButton>
              <span className="IconContainer">
                <svg
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fillRule="evenodd"
                  clipRule="evenodd"
                >
                  <path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
                </svg>
              </span>
              <p className="text">Accedi</p>
            </LoginButton>
          </NavMenuMobile>
        )}
      </NavbarStyles>
    </HeaderWrapper>
  );
}

export default Navbar;
