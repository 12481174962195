import styled from 'styled-components';
import { LightColor } from '../utils/StyleVariables';

export const FooterWrapper = styled.footer`
  background-color: #008944;
  padding: 50px 0;
`;

export const FooterRow = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  width: 90%;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media (max-width: 999px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`;

export const FirstCol = styled.div`
  h3 {
    margin-top: 5px;
    font-family: "Tobi Pro", sans-serif;
    color: ${LightColor};
    text-transform: uppercase;
    text-shadow: 0.5px 0.5px black;
  }

  div.h6-div {
    font-family: "Merriweather", sans-serif;
    font-size: 18px;
    margin-bottom: 5px;
    color: ${LightColor};
    font-weight: 900;
    position: relative;
    text-shadow: 0.5px 0.5px black;

    &::before {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 0;
      background: ${LightColor};
      height: 5px;
      width: 20px;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    font-weight: 500;
    li {
      padding: 5px 0;
      color: ${LightColor};
      text-shadow: 0.5px 0.5px black;
      a {
        text-decoration: none;
        color: ${LightColor};
        font-weight: 500;
      }
    }
  }

  img {
    width: 200px;
  }
`;
export const SecondCol = styled.div`
  div.h6-div {
    font-family: "Merriweather", sans-serif;
    font-size: 18px;
    margin-bottom: 5px;
    color: ${LightColor};
    font-weight: 900;
    position: relative;
    text-shadow: 0.5px 0.5px black;

    &::before {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 0;
      background: ${LightColor};
      height: 5px;
      width: 20px;
    }
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      padding: 5px 0;
      font-weight: 500;
      text-shadow: 0.5px 0.5px black;
      a {
        text-decoration: none;
        color: ${LightColor};
        font-weight: 500;
      }

      p {
        text-decoration: underline;
        color: ${LightColor};
        font-weight: 500;
        cursor: pointer;
        margin-bottom:0px;
      }
    }
  }
`;
export const ThirdCol = styled.div`
  div.h6-div {
    font-family: "Merriweather", sans-serif;
    font-size: 18px;
    margin-bottom: 5px;
    color: ${LightColor};
    font-weight: 900;
    position: relative;
    text-shadow: 0.5px 0.5px black;

    &::before {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 0;
      background: ${LightColor};
      height: 5px;
      width: 20px;
    }
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 5px 0;
      color: ${LightColor};
      gap: 10px;
      font-weight: 500;
      text-shadow: 0.5px 0.5px black;
      a {
        text-decoration: none;
        color: ${LightColor};
        font-weight: 500;
      }
    }
  }
`;
