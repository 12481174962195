import styled from 'styled-components';
import { PrimaryColor } from '../utils/StyleVariables';

export const PaginationStyles = styled.div`
.active>.page-link{
    background-color: ${PrimaryColor};
    border-color: ${PrimaryColor};
    color: white;
}

.active>.page-link:focus{
    background-color: #006432;
    box-shadow: 0 0 0 0.25rem rgba(0 , 137 , 68, 0.3);
}

.page-link{
    color: ${PrimaryColor};
}

.disabled>.page-link {
    color: gray;

}

.page-link:focus{
    background-color: #DDFFEE;
    box-shadow: 0 0 0 0.25rem rgba(0 , 137 , 68, 0.3);
}

`;
