import axios from 'axios';
import Swal from 'sweetalert2';
import { BACKEND_API_URL } from './emailUrl';

export const sendContactData = async (data, setLoading, navigate) => {
  try {
    setLoading(true);
    const res = await axios.post(`${BACKEND_API_URL}/send-contact-data`, data, {
      headers: {
        Accept: 'application/json',
      },
    });

    if (res.status === 200) {
      navigate('/thank-you');
    }
    setLoading(false);
  } catch (error) {
    if (error.response.status !== 429) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Questa e-mail è già stata contattata o lo sarà a breve!',
      });
    }

    if (error.response.status === 429) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Contatti non inviati! Riprova piu tardi!',
      });
    }
    setLoading(false);
  }
};
