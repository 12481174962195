import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

export const generalAlertError = (message, errorTitle) => {
  Swal.fire({
    icon: 'error',
    title: errorTitle ?? 'Errore',
    text: message,
  });
};
