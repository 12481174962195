import styled from 'styled-components';

export const ThankYouContainer = styled.div`
    height: 500px;

    h1{
        font-family: "Tobi Pro", sans-serif;
        color: #008944;
        font-size: 50px;
        margin-bottom: 20px;
    }
`;
