import React from 'react';
import { motion } from 'framer-motion';
import {
  ImageContainer,
  InfoContainer,
  ProductInfoRow,
  ProductsInfoWrapper,
} from './ProductsInfo.styles';
import firstImg from '../assets/images/firstImg.webp';
import secondImg from '../assets/images/secondImg.webp';
import solution from '../assets/images/solution.svg';
import documentsCloud from '../assets/images/documents-cloud.svg';

export function ProductsInfo() {
  const visible = { opacity: 1, y: 0, transition: { duration: 0.5 } };

  return (
    <ProductsInfoWrapper id="product-info">
      <div className="container">
        <ProductInfoRow>
          <ImageContainer className="start">
            <img src={firstImg} alt="Greenia" />
          </ImageContainer>
          <InfoContainer
            initial="hidden"
            animate="visible"
            exit={{ opacity: 0, transition: { duration: 1 } }}
            variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
          >
            <img src={solution} alt="Semplifica le tue giornate con Greenia" />

            <motion.h3
              variants={{
                hidden: { opacity: 0, y: -20 },
                visible,
              }}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.8 }}
            >
              Semplifica le tue giornate con Greenia
            </motion.h3>
            <motion.p
              variants={{
                hidden: { opacity: 0, y: -20 },
                visible,
              }}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.6 }}
            >
              Il tuo alleato assicurativo che fa vibrare la tua vita! Entra
              nell'azione con mandati esclusivi dalle compagnie top del mercato e
              gestisci il tuo portafoglio in modalità 100% digitale. Scatena la
              potenza di un click! Con un'interfaccia unica, genera una pioggia di
              proposte di polizza. E il meglio? Puoi salvare i preventivi per quei
              momenti da brivido in cui vuoi decidere il colpo vincente. Stile e
              praticità si fondono: scegli la modalità di pagamento che fa per te
              e chiudi il match direttamente sulla piattaforma. E sicuro che sia
              sicuro? Assolutamente! Livelli di protezione da urlo per acquisti da
              campioni. Non sei mai solo in questa partita. Con il gestionale di
              Greenia, coordina la tua squadra con stile. Accessi differenziati
              per una collaborazione perfetta, come il passaggio del testimone
              nella staffetta.
            </motion.p>
          </InfoContainer>
        </ProductInfoRow>
        <ProductInfoRow>
          <InfoContainer
            initial="hidden"
            animate="visible"
            exit={{ opacity: 0, transition: { duration: 1 } }}
            variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
          >
            <img
              src={documentsCloud}
              alt=" Libera la tua mente dai mal di testa documentali con un tocco di
            modernità!"
            />
            <motion.h3
              variants={{
                hidden: { opacity: 0, y: -20 },
                visible,
              }}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.8 }}
            >
              Libera la tua mente dai mal di testa documentali con un tocco di
              modernità!
            </motion.h3>
            <motion.p
              variants={{
                hidden: { opacity: 0, y: -20 },
                visible,
              }}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.6 }}
            >
              Esplora il mondo digitale di archiviazione automatica di Greenia,
              dove la documentazione di polizza trova finalmente la sua dimora
              centralizzata. Naviga attraverso le tue cartelle digitali
              organizzate con maestria e preleva i documenti di cui hai bisogno al
              volo. Addio alla carta tradizionale! Firma i documenti di polizza in
              stile digitale, regalando un tocco high-tech alla tua routine. Basta
              con l'ansia delle fatture! Grazie al nostro sistema automatico,
              Greenia gestisce tutto in modo impeccabile. E c'è di più! Abbraccia
              il supporto gratuito del tuo consulente personale, pronto a guidarti
              nell'organizzazione del lavoro e nella sfida delle scadenze. Con
              Greenia, sei sul ring con il tuo angolo di consulenza sempre pronto
              a darti una mano.
            </motion.p>
          </InfoContainer>
          <ImageContainer className="end">
            <img src={secondImg} alt="Assicurazioni Autocarro" />
          </ImageContainer>
        </ProductInfoRow>
      </div>
    </ProductsInfoWrapper>
  );
}

export default ProductsInfo;
