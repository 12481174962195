import styled from 'styled-components';
import { motion } from 'framer-motion';
import { LightColor, PrimaryColor } from '../utils/StyleVariables';

export const ShortInfoSectionStyles = styled.section`
  margin-top: 60px;

  @media screen and (max-width: 768px) {
    margin: 60px auto;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 999px) {
    flex-direction: column;
    gap: 0px;
    row-gap: 30px;
    padding: 0 10px;
  }
`;

export const Card = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.3);
  text-align: center;
  width: 100%;

  h3 {
    font-family: "Tobi Pro", sans-serif;
    font-size: 28px;
    color: ${PrimaryColor};
    margin-bottom: 0;
  }


  background-color: ${LightColor};

  @media (max-width: 999px) {
    width: 90%;
  }
`;
