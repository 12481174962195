import React from 'react';
import { CardTeam, MoreInfoTeam } from '../pages/Team.styles';
import { REACT_APP_BLOG_IMG_URL } from '../config';

function TeamCard({ team }) {
  return (
    <div className="col-12 col-md-6 col-lg-4 mb-3">
      <CardTeam className="card">
        <img className="card-img-top" src={`${REACT_APP_BLOG_IMG_URL}team/${team.image}`} alt={team.full_name} />
        <div className="card-body">
          <a href={`/team/${team.slug}`} className="text-decoration-none">
            <h2 className="mb-3">
              {team.full_name}
            </h2>
          </a>
          {team.excerpt ? (
            <p>
              {team.excerpt.substring(0, 85)}
              {' '}
              {team.excerpt.length >= 85 && '...'}
            </p>
          ) : (
            <p>
              {team.body}
            </p>
          )}
          <MoreInfoTeam className="d-flex justify-content-between align-items-center px-1">
            <h6 className="m-0">{team.role}</h6>
            <a href={`/team/${team.slug}`} className="btn btn-success text-decoration-none px-3">Leggi</a>
          </MoreInfoTeam>
        </div>
      </CardTeam>
    </div>
  );
}

export default TeamCard;
