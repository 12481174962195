import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import {
  HeaderLink,
  HeaderStyles,
  HeroStyles,
  HomeWrapper,
  TextPrimary,
  Wrapper,
  WrapperSection,
} from './Home.styles';
import ShortInfoSection from '../components/ShortInfoSection';
import OurProducts from '../components/OurProducts';
import { ProductsInfo } from '../components/ProductsInfo';
import FAQSection from '../components/FAQSection';
import Partners from '../components/Partners';
import Footer from '../components/Footer';

function Home() {
  const [show, setShow] = useState(false);

  const closeMenu = () => {
    if (show === true) {
      setShow(false);
    }
  };

  const location = useLocation();

  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  useEffect(() => {
    const elementId = location.hash.substring(1);
    scrollToElement(elementId);
  }, [location]);

  const toTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <Helmet>
        <title>Greenia SRL | Il primo ecosistema phygital per l’assicuratore</title>
        <meta name="description" content="Greenia offre un ecosistema phygital all’avanguardia su misura per gli assicuratori, integrando perfettamente i progressi digitali con un servizio personalizzato per migliorare l’esperienza assicurativa." />
        <meta
          name="keywords"
          content="assicurazioni, soluzioni AI, coperture assicurative, polizze, rischi assicurativi, sinistri, automatizzazione processi, analisi predittiva, sicurezza finanziaria, assistenza clienti, piattaforma digitale, innovazione assicurativa, gestione dei dati, tariffe competitive, esperienza utente migliorata, personalizzazione delle offerte"
        />
      </Helmet>
      <HomeWrapper onClick={() => closeMenu()}>
        <HeaderStyles className="background-home">
          <Navbar show={show} setShow={setShow} />
          <HeroStyles className="home-styles">
            <div className="hero-container">
              <h1>Il Primo Ecosistema Assicurativo Phygitale</h1>
              <p className="mt-4 mb-0">
                Powered By
                AI
              </p>
              <HeaderLink>
                <Link className="styled-link" to="/onboarding" onClick={toTop}>Piu Informazioni</Link>
              </HeaderLink>
            </div>
          </HeroStyles>
        </HeaderStyles>
        <WrapperSection>
          <TextPrimary>Il primo ecosistema phygitale assicurativo</TextPrimary>
          {/* <PreventivButtonContainer>
            <button onClick={() => navigate('/auto/funnel')}>Vai Al Preventivatore Auto</button>
          </PreventivButtonContainer> */}
          <Wrapper>
            <ShortInfoSection />
          </Wrapper>
        </WrapperSection>
        <OurProducts />
        <ProductsInfo />
        <Partners />
        <FAQSection />
        <Footer />
      </HomeWrapper>
    </>
  );
}

export default Home;
