import styled from 'styled-components';
import { motion } from 'framer-motion';
import { LightColor, PrimaryColor } from '../utils/StyleVariables';

export const OnBoardingInputSection = styled(motion.div)`
  max-width: 1440px;
  padding: 70px 0;
  margin: 0 auto;
  min-height: 600px;
  width: 50%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;

  p {
    color: red;
    margin: 0;
    margin-top: 5px;
    margin-left: 5px;
    font-size: 13px;
  }

  h1 {
    /* text-transform: capitalize; */
    text-align: center;
    color: #474747;
    font-weight: 600;
    margin: 45px 0 30px 0;
  }

  h2 {
    text-transform: capitalize;
    text-align: center;
    color: #474747;
    font-weight: 400;
    margin: 0px 0 50px 0;
  }

  .message-user {
    margin-top: 40px;
  }

  .message-box {
    border-radius: 10px;
    border: 1px solid #f5f5f5;
    padding: 15px;
    background-color: ${PrimaryColor};
  }

  .message-box p {
    margin: 0;
    color: white;
    font-size: 15px;
    text-align: start;
  }

  @media (max-width: 550px) {
    width: 90%;

    h1 {
      font-size: 35px;
      line-height: 40px;
    }
  }

  @media (min-width: 551px) and (max-width: 999px) {
    margin-top: 0px;
    width: 90%;
    h1 {
      font-size: 35px;
      line-height: 40px;
    }
  }

  @media screen and (min-width: 1000px) {
    margin-top: 0px;
    width: 90%;
    h1 {
      line-height: 40px;
    }
  }
`;
export const RobotHead = styled(motion.div)`
  border: 3px dotted ${PrimaryColor};
  padding: 20px;
  border-radius: 10%;

  img {
    width: 80px;
    margin: 0 auto;
  }
`;

export const RadioButton = styled(motion.div)`
  width: 350px;
  padding: 20px;
  border-radius: 7px;
  background-color: #f5f5f5;
  margin-top: 10px;
  display: flex;
  justify-content: start;
  align-items: center;

  input[type="radio"] {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    accent-color: ${PrimaryColor};
  }

  label {
    font-size: 20px;
  }
`;

export const OnBoardingButton = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;

  button {
    background-color: ${PrimaryColor};
    border: 1px solid ${PrimaryColor};
    border-radius: 10px;
    padding: 12px 40px;
    color: ${LightColor};
    font-weight: 700;
    transition: all 250ms linear;
    text-transform: uppercase;
    font-size: 18px;
    box-shadow: rgba(0, 137, 68, 0.6) 0px 10px 40px -10px;

    &:hover {
      background-color: #006432;
      color: ${LightColor};
    }
  }
`;

export const OnBoardingInput = styled(motion.div)`
  input {
    border-radius: 7px;
    padding: 20px;
    border: 1px solid #f5f5f5;
    color: black;
    margin-top: 10px;
    background-color: #f5f5f5;

    &:focus-visible {
      outline: 2px solid ${PrimaryColor};
    }
  }

  p {
    color: red;
    margin: 0;
    margin-top: 5px;
    margin-left: 5px;
    font-size: 13px;
  }
  @media (min-width: 501px) and ((max-width: 768px)) {
    textarea {
      width: 600px;
    }
  }
`;

export const AnswerResponse = styled.div`
  width: 600px;

  h6 {
    margin-top: 40px;
    width: 90%;
    font-size: 14px;
  }

  @media screen and (max-width: 768px) {
    width: 90%;
  }

  /* HTML: <div class="loader"></div> */
.loader {
  width: 45px;
  aspect-ratio: 4;
  --_g: no-repeat radial-gradient(circle closest-side,#fff 90%,#0000);
  background: 
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 100%;
  animation: l7 1s infinite linear;
}
@keyframes l7 {
    33%{background-size:calc(100%/3) 0%  ,calc(100%/3) 100%,calc(100%/3) 100%}
    50%{background-size:calc(100%/3) 100%,calc(100%/3) 0%  ,calc(100%/3) 100%}
    66%{background-size:calc(100%/3) 100%,calc(100%/3) 100%,calc(100%/3) 0%  }
}
`;

export const OnBoardingTextArea = styled(motion.div)`
  width: 42%;
  display: flex;
  justify-content: center;
  textarea {
    width: 100%;
    margin-top: 30px;
    border-radius: 10px;
    border: 1px solid #f5f5f5;
    background-color: #f5f5f5;
    padding: 10px;
    resize: none;

    &:focus-visible {
      outline: 2px solid ${PrimaryColor};
    }
  }

  @media screen and (max-width: 768px) {
    width: 90%;
  }
  @media (min-width: 769px) and (max-width: 1200px) {
    width: 70%;
  }
  @media (min-width: 1201px) and (max-width: 1400px) {
    width: 60%;
  }
`;

export const RecaptchaDiv = styled.div`
  margin-top: 20px;
`;
