import React from 'react';
import { motion } from 'framer-motion';
import {
  OurProductsWrapper,
  ProductCard,
  ProductsContainer,
} from './OurProducts.styles';
import auto from '../assets/images/auto.svg';
import autocarro from '../assets/images/autocarro.svg';
import professionale from '../assets/images/professione.svg';
import viaggi from '../assets/images/travel-insurance.svg';
// import house from '../assets/images/house.svg';
import tutelaLegale from '../assets/images/tutela-legale.svg';

const cardVariants = {
  offscreen: { opacity: 0, y: -20 },
  onscreen: {
    opacity: 1,
    y: 0,

    transition: {
      duration: 0.8,
    },
  },
};
const products = [
  {
    img: auto,
    name: 'Auto',
  },
  {
    img: autocarro,
    name: 'Autocarro',
  },
  {
    img: viaggi,
    name: 'Viaggi',
  },
  {
    img: professionale,
    name: 'Professione',
  },
  {
    img: tutelaLegale,
    name: 'Tutela Legale',
  },
  // {
  //   img: house,
  //   name: 'Casa',
  // },
];

function OurProducts() {
  return (
    <OurProductsWrapper id="our-products">
      <ProductsContainer>
        <div className="container">
          {products.map((product, index) => (
            <ProductCard
              key={index}
              className={index !== 4 ? 'border-custom' : ''}
            >
              <motion.img
                src={product.img}
                alt={product.name}
                variants={cardVariants}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
              />
              <motion.p
                variants={cardVariants}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
                className="m-0"
              >
                {product.name}
              </motion.p>
            </ProductCard>
          ))}
        </div>
      </ProductsContainer>
    </OurProductsWrapper>
  );
}

export default OurProducts;
