import styled from 'styled-components';
import { PrimaryColor } from '../utils/StyleVariables';

export const PrivacyPolicyStyles = styled.div``;

export const PrivacyTitle = styled.div`
    h2{
        text-align: center;
        font-family: "Merriweather", sans-serif;
        font-weight: 700;
        color: ${PrimaryColor};
        font-size: 40px;
        margin-bottom: 0;
        font-size: 25px;
        margin-top: 50px;
    }
`;
export const PrivacyBody = styled.div`
    width: 70%;
    margin: 0 auto;
    margin-top: 40px;

    .parag-semibold{
        font-weight: 450;
        margin-top: 20px;
    }

    h5{
        font-size: 19px;
        text-align: center;
        font-family: "Merriweather", sans-serif;
        font-weight: 500;
        color: ${PrimaryColor};
    }

    p{
        text-align: center;
        font-size: 15px;
    }
    p a{
      color: ${PrimaryColor};
    }

    @media screen and (max-width: 1200px) {
    width: 90%;
  }
  
`;

export const BrowsersList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ul li{
    font-size: 15px;
    color: #212529;
  }
  li::marker{
    color: ${PrimaryColor};
  }
`;

export const TableWrapper = styled.div`
  width: 70%;
  margin-top: 0px;
  
  margin-left: auto !important;
  margin-right: auto !important;

  @media screen and (max-width: 768px) {
    width: 98%;
    margin-top: 50px;
  }
`;

export const Table = styled.div`
  table {
    width: 70%;
    margin: 0px auto;
    margin-top: 25px;
    margin-bottom: 40px;
    box-shadow: 10px 10px 10px rgba(0, 164, 81, 0.3);

    thead {
      background-color: ${PrimaryColor};
    }

    th {
      padding-left: 10px;
      padding-top: 10px;
      border: 1px solid ${PrimaryColor};
    }
    td {
      border: 1px solid ${PrimaryColor};
      text-align: left;
      padding: 10px;
      font-size: 15px;
    }
    h1 {
      font-size: 20px;
      font-weight: 400;
      text-align: left;
      color: white;
    }

    .total {
      background-color: ${PrimaryColor};
      color: white;
    }
  }

  @media (min-width: 350px) and (max-width: 1200px) {
    table {
      width: 98%;
      margin: 15px auto;
      box-shadow: 10px 10px 10px rgba(0, 164, 81, 0.3);

      h1 {
        font-size: 15px;
        padding: 5px;
        font-weight: 500;
        text-align: center;
      }
      th {
        padding-left: 0px;
      }
      td {
        border: 1px solid ${PrimaryColor};
        padding: 5px;
        font-size: 15px;
      }
    }
  }

  @media (min-width: 1201px) and (max-width: 1399px) {
    table {
      width: 80%;
      margin: 15px auto;
      box-shadow: 10px 10px 10px rgba(0, 164, 81, 0.3);

      h1 {
        font-size: 15px;
        padding: 5px;
        font-weight: 500;
        text-align: center;
      }
      th {
        padding-left: 0px;
      }
      td {
        border: 1px solid ${PrimaryColor};
        padding: 5px;
        font-size: 15px;
      }
    }
  }
`;
