import styled from 'styled-components';
import { motion } from 'framer-motion';

import { LightColor, PrimaryColor } from '../utils/StyleVariables';

export const CompagnieConfrontateWrapper = styled.div`
  width: 100%;
  max-width: 100%;
`;

export const CompagnieHeaderStyles = styled.div`
 
  position: relative;
  padding: 20px 0px 0px 0px;
`;

export const LottieBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const LottieBackgroundMobile = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const CompagnieHeroStyles = styled.div`
  /* max-width: 1440px; */
  margin: 10px auto 0 auto;
  padding: 50px;
  background-color: rgb(0, 142, 71);

  & .hero-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    max-width: 100%;
    margin-left: auto;
    width: 60%;
    display: table;
  }

  @media (max-width: 550px) {
    margin: 0px;
    padding: 0px;
    &,
    .hero-container {
      flex-direction: column;
      justify-content: space-between;
    }
  }

  @media (min-width: 551px) and (max-width: 999px) {
    margin-top: 0px;
    justify-content: space-between;
    padding: 0 20px;
    .hero-container {
      flex-direction: column;
      justify-content: space-between;
    }
  }
  @media (min-width: 920px) and (max-width: 999px) {
    img {
      width: 50%;
    }
  }
  @media (min-width: 1000px) and (max-width: 1200px) {
    img {
      width: 65%;
    }
  }
`;

export const CompagnieTextBox = styled(motion.div)`
  align-self: flex-start;
  margin-left: 20px;
  margin-top: 20px;
  width: 60%;

  h2 {
    font-family: "Merriweather", sans-serif;
    font-weight: 700;
    color: ${LightColor};
    font-size: 38px;
    margin-bottom: 0;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 550px) {
    width: 80%;
    margin-top: 40px;
    margin-left: 0px;
    align-self: center;

    h2 {
      font-size: 25px;
    }
  }

  @media (min-width: 551px) and (max-width: 999px) {
    width: 100%;
    margin-top: 50px;
    /* padding: 20px 20px 80px 20px; */

    h2 {
      font-size: 33px;
      margin-top: 0px;
    }
  }
  @media (min-width: 1000px) and (max-width: 1200px) {
    width: 100%;
  }
`;

// export const ImgDiv = styled.div`
//   @media screen and (max-width: 768px) {
//     margin-left: 40px;
//     display: flex;
//     justify-content: end;
//     margin-top: 30px;
//   }
// `;
export const TableWrapper = styled.div`
  width: 70%;
  margin-top: 0px;
  margin-bottom: 100px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 100px;
  }
`;

export const CenterTitle = styled.div`
  display: flex;
  justify-content: center;
`;

export const MainTitle = styled.div`
  width: 85%;
  margin: 40px 0px;

  h2 {
    text-align: center;
    font-family: "Merriweather", sans-serif;
    font-weight: 700;
    color: ${PrimaryColor};
    font-size: 40px;
    margin-bottom: 0;
    font-size: 25px;
  }

  @media (min-width: 350px) and (max-width: 999px) {
    width: 90%;
    h2 {
      font-size: 19.5px;
    }
  }
`;

export const Table = styled(motion.div)`
  table {
    width: 80%;
    margin: 50px auto;
    box-shadow: 10px 10px 10px rgba(0, 164, 81, 0.3);

    thead {
      background-color: ${PrimaryColor};
    }

    th {
      padding-left: 10px;
      padding-top: 10px;
      border: 1px solid ${PrimaryColor};
    }
    td {
      border: 1px solid ${PrimaryColor};
      text-align: left;
      padding: 10px;
    }
    h1 {
      font-size: 20px;
      font-weight: 400;
      text-align: left;
      color: white;
    }

    .total {
      background-color: ${PrimaryColor};
      color: white;
    }
  }

  @media (min-width: 350px) and (max-width: 999px) {
    table {
      width: 98%;
      margin: 15px auto;
      box-shadow: 10px 10px 10px rgba(0, 164, 81, 0.3);

      h1 {
        font-size: 15px;
        padding: 5px;
        font-weight: 500;
        text-align: center;
      }
      th {
        padding-left: 0px;
      }
      td {
        border: 1px solid ${PrimaryColor};
        text-align: left;
        padding: 5px;
        font-size: 15px;
      }
    }
  }
`;
