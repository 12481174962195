import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  formData: {
    loading: false,
    error: false,
  },
};

export const chatFormSlice = createSlice({
  name: 'chatForm',
  initialState,
  reducers: {
    chatFormPending: (state) => {
      state.formData.loading = true;
      state.formData.error = null;
    },
    chatFormRejected: (state, { payload }) => {
      state.formData.loading = false;
      state.formData.error = payload;
    },
    chatFormSuccess: (state) => {
      state.formData.loading = false;
    },
  },
});
