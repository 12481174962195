import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export function Redirector() {
  const [query] = useSearchParams();

  useEffect(() => {
    window.open(query.get('url'), '_self');
  }, [query]);

  return <div />;
}
