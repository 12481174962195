import { configureStore } from '@reduxjs/toolkit';

import { formsSlice } from './forms/formsSlice';
import { resultsSlice } from './results/resultsSlice';
import { chatFormSlice } from './chatForm/chatFormSlice';
import { blogsSlice } from './blogs/blogsSlice';
import { teamsSlice } from './team/teamSlice';

export const store = configureStore({
  reducer: {
    forms: formsSlice.reducer,
    results: resultsSlice.reducer,
    chatForm: chatFormSlice.reducer,
    blogs: blogsSlice.reducer,
    teams: teamsSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});
