import styled from 'styled-components';
import { motion } from 'framer-motion';
import { LightColor, PrimaryColor } from '../utils/StyleVariables';
import backgroundImgAvif from '../assets/images/backgroundHeader.avif';
import backgroundImgMobile from '../assets/images/background-mobile.avif';

export const Wrapper = styled.div`
  max-width: 1440px;
  /* padding: 0 20px; */
  margin: 0 auto;

  @media (max-width: 999px) {
    padding: 0px;
  }
`;

export const HomeWrapper = styled.div`
  width: 100%;
  max-width: 100%;
`;

export const HeaderWrapper = styled.header`
  max-width: 1440px;
  padding: 0 20px;
  margin: 0 auto;

  @media screen and (max-width: 550px) {
    padding: 20px 10px;
  }
`;

export const HeaderStyles = styled.div`
  &.background-home {
    background: url(${backgroundImgAvif}) no-repeat bottom center/cover;
  }
  margin-top: 20px;

  .understand-text{
    border: 1px solid  #565656;
    /* background-color: #f5f5f5; */
    border-radius: 10px;
    width: 65%;

    h5{
      color: #565656;
      font-size: 17px;
      font-weight: 450;
    }

    h4{
      color: #565656;
      font-size: 22px;
      font-weight: 450;
    }
  }

  .accept-terms{
    width: 32%;

    label{
      font-size: 12px;
      
    }
   
  .form-check-input:checked{
        background-color: ${PrimaryColor};
        border-color: ${PrimaryColor};

      }
  .form-check-input:focus{
      border-color: ${PrimaryColor};
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgba(0 , 137 , 68,.25);

      }
  
  }

  @media screen and (max-width: 1000px) {
    .understand-text{
    border: 1px solid  #565656;
    /* background-color: #f5f5f5; */
    border-radius: 10px;
    width: 95%;

    h5{
      color: #565656;
      font-size: 17px;
      font-weight: 450;
    }

    h4{
      color: #565656;
      font-size: 22px;
      font-weight: 450;
    }
  }}
  @media screen and (max-width: 500px) {
    .accept-terms{
    width: 90%;

    label{
      font-size: 12px;
      
    } }
  }

  @media (min-width: 501px) and (max-width: 720px) {
    .accept-terms{
    width: 70%;

    label{
      font-size: 12px;
      
    } }
  }

  @media (min-width: 721px) and (max-width: 1119px) {
    .accept-terms{
    width: 50%;

    label{
      font-size: 12px;
      
    } }
  }

  @media screen and (max-width: 1120px) {
    &.background-home {
      background: url(${backgroundImgMobile}) no-repeat bottom left/cover;
    }
    /* .accept-terms{
    width: 90%;

    label{
      font-size: 12px;
      
    } } */
  }
`;

export const HomeLottieBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
export const HomeLottieBackgroundMobile = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const HeroStyles = styled.div`
  max-width: 1440px;
  padding: 150px 0;
  /* margin: 50px auto 0 auto; */
  margin: 0 auto;
  height: 650px;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    text-transform: capitalize;
    line-height: 60px;
    text-align: center;
    color: #474747;
    font-weight: bold;
    font-size: 50px;
  }

  p {
    text-align: center;
    margin-top: 20px;
    color: #474747;
    font-size: 15px;
  }

  & .hero-container {
    /* display: flex;
    align-items: center;
    justify-content: center; */
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  /* img {
    height: 610px;
    margin-top: 23px;
  } */
  @media (max-width: 550px) {
    height: 800px;

    h1 {
      font-size: 45px;
      line-height: 50px;
    }

    & .hero-container {
      margin-top: 50px;
      /* flex-direction: column; */
      /* justify-content: center; */
      padding: 0 20px;
      width: 90%;
    }

    &.home-styles {
      align-items: flex-start;
      padding: 40px 0;
    }
  }

  @media (min-width: 551px) and (max-width: 999px) {
    margin-top: 0px;
    /* justify-content: space-between; */
    /* padding: 0 20px; */
    height: 700px;

    & .hero-container {
      width: 80%;
    }
    &.home-styles {
      align-items: flex-start;
      padding: 40px 0;
    }

    /* img {
      max-width: 45%;
      margin-left: auto;
      margin-right: auto;
    } */
  }

  @media (min-width: 1921px) {
    min-height: 85vh;
    margin-top: 0px;
    justify-content: space-between;
    padding: 0 20px;

    img {
      max-width: 80%;
      margin-left: auto;
    }
  }
`;

export const TextBox = styled(motion.div)`
  width: 45%;

  align-self: flex-start;

  h2 {
    font-family: "Merriweather", sans-serif;
    font-weight: 700;
    color: ${LightColor};
    font-size: 60px;
    margin-bottom: 0;
    text-shadow: 0.5px 0.5px black;
  }

  p {
    color: ${LightColor};
    text-shadow: 0.5px 0.5px black;
  }

  @media (max-width: 550px) {
    width: 100%;
    padding: 20px;

    align-self: center;

    h2 {
      font-size: 30px;
      text-shadow: 0.5px 0.5px black;
    }

    p {
      font-size: 14px;
      text-shadow: 0.5px 0.5px black;
    }
  }

  @media (min-width: 551px) and (max-width: 999px) {
    width: auto;
    margin-top: 70px;
    padding: 20px 20px 20px 20px;

    h2 {
      font-size: 35px;
      margin-top: 0px;
      text-shadow: 0.5px 0.5px black;
    }

    p {
      font-size: 14px;
      text-shadow: 0.5px 0.5px black;
    }
  }
`;

export const FormaContatto = styled.form`
  span {
    color: red;
    font-size: 11px;
    margin-top: 0px;
    text-align: start;
    display: block;
  }

  input {
    padding: 10px;
    margin-right: 5px;
    margin-bottom: 15px;
    border-radius: 10px;
    border: none;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.3);
    transition: all 200ms ease-in-out;

    &:focus {
      outline: none;
      border: none;
      box-shadow: 1px 1px 5px 1px #000;
    }
  }

  button {
    padding: 8px 20px;
    border-radius: 10px;
    border: 2px solid ${LightColor};
    background-color: transparent;
    color: ${LightColor};
    font-family: "Merriweather", sans-serif;
    font-weight: 600;
    transition: all 200ms ease-in-out;
    cursor: pointer;
    margin-top: 10px;

    &:hover {
      background-color: ${LightColor};
      color: ${PrimaryColor};
    }
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  label {
    color: ${LightColor};
    font-size: 12px;
    text-shadow: 0.5px 0.5px black;
  }

  div.label {
    display: flex;
    gap: 10px;
    text-shadow: 0.5px 0.5px black;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      transition: all 200ms ease-in-out;
    }

    .checkmark {
      position: relative;
      box-shadow: rgb(0, 0, 0) 0px 0px 0px 2px;
      background-color: rgba(16, 16, 16, 0.5);
      height: 20px;
      width: 20px;
      margin-right: 10px;
      flex-shrink: 0;
      margin-top: -1px;
      transition: all 0.2s ease 0s;
      cursor: pointer;
      transform-origin: 0px 10px;
      border-radius: 4px;
      margin: -1px 10px 0px 0px;
      padding: 0px;
      box-sizing: border-box;
    }

    input:checked ~ .checkmark {
      box-shadow: rgb(255, 84, 0) 0px 0px 0px 2px;
      background-color: rgba(245, 24, 24, 0.5);
      height: 20px;
      width: 20px;
      margin-right: 10px;
      flex-shrink: 0;
      margin-top: -1px;
      transition: all 0.2s ease 0s;
      cursor: pointer;
      transform-origin: 0px 10px;
      border-radius: 4px;
      margin: -1px 10px 0px 0px;
      padding: 0px;
      box-sizing: border-box;
    }

    .checkmark::after {
      content: "";
      position: absolute;
      display: none;
    }

    input:checked ~ .checkmark::after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .checkmark::after {
      left: 0.45em;
      top: 0.25em;
      width: 0.25em;
      height: 0.5em;
      border: solid white;
      border-width: 0 0.15em 0.15em 0;
      transform: rotate(45deg);
      transition: all 500ms ease-in-out;
    }
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 15px;

  div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    input {
      margin-bottom: 3px;
      align-self: flex-start;

      &.invalid {
        border: 1px solid #dc2626;
        box-shadow: 0px 0px 5px 0px #dc2626;
      }
    }

    span {
      color: red;
      font-size: 11px;
      margin-top: 0px;
      text-align: start;
    }
  }

  @media (max-width: 999px) {
    gap: 5px;
    div {
      input {
        width: 85%;
      }
    }
  }
`;

export const TextPrimary = styled.h2`
  text-align: center;
  font-family: "Tobi Pro";
  font-size: 35px;
  /* margin-bottom: 0;
  margin-top: 0; */
  color: ${LightColor};
  /* margin-top: -5%;
  z-index: 9999;
  position: relative; */
`;

export const PreventivButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;

  button {
    background-color: ${LightColor};
    border: 1px solid ${LightColor};
    border-radius: 10px;
    padding: 8px 20px;
    color: ${PrimaryColor};
    font-weight: 700;
    transition: all 250ms linear;

    &:hover {
      background-color: transparent;
      color: ${LightColor};
    }
  }
`;

export const WrapperSection = styled.div`
  background-color: ${PrimaryColor};
  padding: 80px 0;
`;

export const HeaderLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 38px;

  .styled-link {
    background-color: ${PrimaryColor};
    border: 1px solid ${PrimaryColor};
    border-radius: 10px;
    padding: 16px 40px;
    color: ${LightColor};
    font-weight: 700;
    transition: all 250ms linear;
    text-transform: uppercase;
    font-size: 18px;
    box-shadow: rgba(0, 137, 68, 0.6) 0px 10px 40px -10px;
    text-decoration: none;

    &:hover {
      background-color: #006432;
      color: ${LightColor};
    }
  }
`;
