import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import {
  CenterTitle, CompagnieConfrontateWrapper, CompagnieHeaderStyles, CompagnieHeroStyles, CompagnieTextBox, MainTitle, Table, TableWrapper,
} from './CompagnieConfrontate.styles';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import presentMan from '../assets/images/present-man.svg';

function CompagnieConfrontate() {
  const visible = { opacity: 1, y: 0, transition: { duration: 0.5 } };

  const [show, setShow] = useState(false);
  const closeMenu = () => {
    if (show === true) {
      setShow(false);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Greenia SRL | Compagnie Confrontate</title>
      </Helmet>
      <CompagnieConfrontateWrapper onClick={() => closeMenu()}>
        <CompagnieHeaderStyles>
          <Navbar show={show} setShow={setShow} />
          <CompagnieHeroStyles>
            <div className="hero-container container">
              <CompagnieTextBox>
                <motion.h2
                  variants={{
                    hidden: { opacity: 0, y: -20 },
                    visible,
                  }}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.8 }}
                >
                  E' importante capire la quota di mercato interessata dall'offerta diretta di Greenia.it
                </motion.h2>
              </CompagnieTextBox>
              <div>
                <img src={presentMan} alt="" />
              </div>
            </div>
          </CompagnieHeroStyles>

        </CompagnieHeaderStyles>
        <TableWrapper>
          <CenterTitle>
            <MainTitle>
              <motion.h2
                variants={{
                  hidden: { opacity: 0, y: -20 },
                  visible,
                }}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.8 }}
              >
                Con riferimento ai dati ANIA 2017, le compagnie partner di Greenia.it rappresentano una quota del 2% del mercato RCA italiano. La tabella che segue elenca le compagnie confrontate sul nostro sito.
              </motion.h2>
            </MainTitle>
          </CenterTitle>

          <Table
            variants={{
              hidden: { opacity: 0, y: -20 },
              visible,
            }}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.8 }}
          >
            <table>
              <thead>
                <tr>
                  <th><h1>Brand</h1></th>
                  <th><h1>Compagnie</h1></th>
                  <th><h1>Partner</h1></th>
                  <th><h1>Quota Di Marcato</h1></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Linear</td>
                  <td>Linear Assicurazioni S.p.A</td>
                  <td>si</td>
                  <td>1,09%</td>
                </tr>
                <tr>
                  <td>Prima.it</td>
                  <td>Great Lakes Insurance SE</td>
                  <td>si</td>
                  <td>1,48%</td>
                </tr>
                <tr>
                  <td>Adriatic</td>
                  <td>Adriatic Assicurazioni</td>
                  <td>si</td>
                  <td>N.D.</td>
                </tr>
                <tr>
                  <td colSpan="3" className="total">Totale</td>

                  <td>2,57%</td>
                </tr>
              </tbody>
            </table>
          </Table>
        </TableWrapper>
        <Footer />
      </CompagnieConfrontateWrapper>
    </>
  );
}

export default CompagnieConfrontate;
