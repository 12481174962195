import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  blogs: {
    loading: true,
    error: null,
    blogs: [],
    page: 1,
    totalPages: 0,
  },
  blog: {
    loading: true,
    error: null,
    blog: null,
  },
};

export const blogsSlice = createSlice({
  name: 'blogs',
  initialState,
  reducers: {
    blogsPending: (state) => {
      state.blogs.loading = true;
      state.blogs.error = null;
    },
    blogsRejected: (state, { payload }) => {
      state.blogs.loading = false;
      state.blogs.error = payload;
    },
    blogsSuccess: (state, { payload }) => {
      state.blogs.loading = false;
      state.blogs.blogs = payload.data;
      state.blogs.page = payload.data.current_page;
      state.blogs.totalPages = payload.data.last_page;
    },
    blogPending: (state) => {
      state.blog.loading = true;
      state.blog.error = null;
    },
    blogRejected: (state, { payload }) => {
      state.blog.loading = false;
      state.blog.error = payload;
    },
    blogSuccess: (state, { payload }) => {
      state.blog.loading = false;
      state.blog.blog = payload.data;
    },

  },
});
