import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  auto: {
    loading: true,
    error: null,
    insuranceRequest: null,
    quotationAquistaLoading: null,
  },
};

export const resultsSlice = createSlice({
  name: 'results',
  initialState,
  reducers: {
    loadQuotesPending: (state) => {
      state.auto.loading = true;
      state.auto.error = null;
    },
    loadQuotesRejected: (state, { payload }) => {
      state.auto.loading = false;
      state.auto.error = payload;
    },
    loadQuotesSuccess: (state, { payload }) => {
      state.auto.loading = false;
      state.auto.insuranceRequest = payload;
    },
    changeQuotationAquistaLoading: (state, { payload }) => {
      state.auto.quotationAquistaLoading = payload;
    },
  },
});
